/* src/pages/Comparison.css */

/* General Container Styles */
.comparison-container {
  font-family: "Arial", sans-serif;
  color: #333;
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f6f8; /* Light background color */
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.comparison-title {
  text-align: center;
  font-weight: bold;
  font-size: 2em;
  margin-bottom: 20px;
  color: #2c3e50; /* Darker color for title */
}

/* Dropdown Container Styles */
.dropdown-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
}

/* Comparison Sides */
.comparison-sides {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

/* Each Comparison Side (Vehicle + Shift) */
.comparison-side {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

/* General Filters */
.general-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

/* Dropdown Group Styles */
.dropdown-group {
  width: 200px;
}

.dropdown-group label {
  font-size: 14px;
  color: #555;
  margin-bottom: 5px;
}

/* Select Dropdown Styles */
.dropdown {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  font-size: 14px;
  width: 100%;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='5'><path fill='%23999' d='M0 0l5 5 5-5z'/></svg>");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px 5px;
  cursor: pointer;
}

.dropdown:focus {
  outline: none;
  border-color: #2980b9; /* Blue border on focus */
}

/* Clear Filters Button */
.clear-filters-button {
  padding: 8px 16px;
  background-color: #e74c3c; /* Red background */
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.clear-filters-button:hover {
  background-color: #c0392b; /* Darker red on hover */
}

/* Message Styles */
.message {
  text-align: center;
  font-size: 1.2em;
  color: #777;
  margin-top: 50px;
}

/* Chart Section Styles */
.chart-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  margin-top: 30px;
}

.chart-container {
  width: 45%;
  min-width: 300px;
  padding: 10px;
  padding-bottom: 25px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.chart-container h3 {
  text-align: center;
  font-size: 18px;
  color: #34495e; /* Dark blue-gray */
  margin-bottom: 10px;
}

/* KPI Section Styles */
.kpi-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 40px;
  padding: 10px;
  gap: 20px;
}

.kpi-card {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 45%;
  min-width: 300px;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.kpi-card h4 {
  margin-bottom: 20px;
  font-size: 1.5em;
  color: #2980b9; /* Blue color for KPI title */
}

.kpi-card table {
  width: 100%;
  border-collapse: collapse;
}

.kpi-card th,
.kpi-card td {
  border: 1px solid #ddd;
  padding: 8px;
  font-size: 14px;
}

.kpi-card th {
  background-color: #2980b9; /* Blue header */
  color: #fff;
}

.kpi-card td {
  text-align: center;
}

.kpi-card tr:nth-child(even) {
  background-color: #f9f9f9;
}

.kpi-card tr:hover {
  background-color: #f1f1f1;
}

/* Responsive Design */
@media (max-width: 1024px) {
  /* Adjust chart and KPI containers */
  .chart-section {
    flex-direction: column;
    align-items: center;
  }

  .chart-container {
    width: 80%;
  }

  .kpi-section {
    flex-direction: column;
    align-items: center;
  }

  .kpi-card {
    width: 80%;
  }

  /* Adjust dropdown groups for comparison sides */
  .comparison-side .dropdown-group {
    width: 250px;
  }
}

@media (max-width: 768px) {
  /* Further adjustments for smaller screens */
  .dropdown-group {
    width: 150px;
  }

  .dropdown {
    width: 100%;
  }

  .kpi-card table th,
  .kpi-card table td {
    font-size: 12px;
    padding: 6px;
  }

  .kpi-card h4 {
    font-size: 1.2em;
  }

  .chart-container {
    width: 90%;
  }

  .kpi-card {
    width: 90%;
  }

  /* Adjust comparison sides for smaller screens */
  .comparison-side .dropdown-group {
    width: 100%;
  }

  .comparison-sides {
    flex-direction: column;
    align-items: center;
  }
}
