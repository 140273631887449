.safety-container {
  font-family: 'Arial', sans-serif;
  color: #333;
  width: 90%;
  margin: 0 auto;
  max-width: 1200px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.event-table-container {
  margin-bottom: 30px;
}

.event-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
}

.event-table th, .event-table td {
  padding: 10px;
  text-align: center;
  border: 1px solid #ddd;
}

.event-table th {
  background-color: #343a40;
  color: white;
}

.event-table .highlighted-row {
  background-color: #ffcccc;
}

.event-summary, .event-photo, .event-stats {
  margin-top: 30px;
  border: 2px solid #ccc;
  padding: 20px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.section-header {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.event-summary-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.event-summary {
  width: 40%;
}

.event-photo {
  width: 30%;
  text-align: center;
}

.event-photo img {
  width: 100%;
  height: auto;
  border: 1px solid #ddd;
}

.event-stats {
  width: 30%;
  text-align: center;
}

.event-stats img {
  max-width: 100%;
  height: auto;
}

.event-summary p, .event-photo p {
  margin: 5px 0;
}

.event-video {
  text-align: center;
  margin-top: 10px;
  font-size: 0.9rem;
  color: #555;
}

.event-summary-description {
  margin-top: 10px;
}

