/* General Container */
.device-cycle-container {
  font-family: "Arial", sans-serif;
  color: #333;
  margin: 0 auto;
  padding: 20px;
  width: 90%;
  max-width: 1200px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Title */
.title {
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
  font-weight: bold;
}

/* Filters Section */
.filters-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
}

.filter-group {
  min-width: 200px;
}

.filter-form-control {
  width: 100%;
}

/* Days Filter */
.days-filter-container {
  margin-bottom: 30px;
  text-align: center;
}

.days-filter-container h3 {
  margin-bottom: 15px;
  font-size: 1.5rem;
}

.days-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
  max-width: 600px;
  margin: 0 auto;
}

.day-cell {
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 15px;
  cursor: pointer;
  font-weight: bold;
  color: #333;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.day-cell:hover {
  background-color: #e0e0e0;
}

.day-cell.selected-day {
  background-color: #4caf50;
  color: white;
}

.day-cell.anomaly-day {
  background-color: #ffebee;
  color: #c62828;
}

.day-cell.anomaly-day.selected-day {
  background-color: #e57373;
  color: white;
}

/* Chart Container */
.chart-container {
  width: 100% !important;
  margin-top: 30px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 400px;
  text-align: center;
}

.chart-container h3 {
  font-size: 1.5rem;
  color: #333;
  font-weight: bold;
  margin-bottom: 10px;
}

/* Analysis Container */
.analysis-container {
  margin-top: 20px;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.analysis-container h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}

.analysis-container p {
  font-size: 1rem;
  margin: 5px 0;
  color: #333;
}

/* Responsive Design */
@media (max-width: 768px) {
  .filters-section {
    flex-direction: column;
    align-items: center;
  }

  .filter-group {
    width: 100%;
    max-width: 300px;
  }

  .chart-container {
    width: 100%;
    padding: 15px;
  }

  .days-grid {
    grid-template-columns: repeat(7, 1fr);
    gap: 8px;
  }
}

/* Additional Styles */
.filter-form-control .MuiInputBase-root {
  background-color: #fff;
}

.filter-form-control .MuiOutlinedInput-notchedOutline {
  border-color: #ccc;
}

.filter-form-control .MuiInputLabel-outlined {
  color: #333;
}

.filter-form-control .MuiSelect-icon {
  color: #333;
}
