/* src/App.css */

/* Reset default margins and paddings */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Ensure html and body take full height */
html,
body,
#root {
  height: 100%;
}

/* Main App container */
.App {
  display: flex;
  height: 100vh; /* Full viewport height */
  font-family: Arial, sans-serif;
}

/* Sidebar Styling */
.sidebar {
  background-color: #282c34;
  color: white;
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  height: 100vh; /* Full viewport height */
  position: fixed; /* Fix the sidebar to the viewport */
  left: 0;
  top: 0;
  overflow-y: auto; /* Add scroll if content exceeds */
}

/* Content Area Styling */
.content {
  margin-left: 20%; /* Same as sidebar width to prevent overlap */
  width: 80%;
  padding: 20px;
  overflow-y: auto; /* Add scroll if content exceeds */
  height: 100vh; /* Full viewport height */
}

/* Navigation List Styling */
.nav-list {
  list-style-type: none;
  width: 100%;
  padding: 0;
  margin-top: 20px;
}

/* Navigation Link Styling */
.nav-link {
  display: block;
  padding: 15px 20px;
  margin-bottom: 10px;
  background-color: #4CAF50;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  color: white;
  font-size: 18px;
  transition: background-color 0.3s ease;
}

.nav-link:hover {
  background-color: #45a049;
}

/* Active Navigation Link Styling */
.nav-link.active {
  background-color: #1abc9c;
}

/* Header Styling */
.Header {
  position: fixed;
  top: 0;
  left: 20%;
  width: 80%;
  background-color: #f1f1f1;
  padding: 10px 20px;
  z-index: 1000;
}

/* Responsive Design */
@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }

  .content {
    margin-left: 0;
    width: 100%;
  }

  .Header {
    left: 0;
    width: 100%;
  }
}

/* Additional Styling (if needed) */

/* Example: Button Styling */
button {
  padding: 10px 20px;
  background-color: #4CAF50;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #45a049;
}

/* Explanation Section Styling */
.explanation {
  width: 90%; /* Utilize more of the sidebar width */
  max-width: 250px; /* Adjust to match button width */
  margin: 20px auto; /* Center alignment */
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  font-size: 0.9rem;
  text-align: left;
}

.explanation h4 {
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.explanation p {
  margin: 0;
}

.secondary-explanation {
  margin-top: 15px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.05);
  border-left: 5px solid #4CAF50;
  border-radius: 5px;
  font-size: 0.85rem;
}

/* Table Container Styling */
.table-container {
  max-height: 400px; /* Set the maximum height of the table */
  overflow-y: auto;  /* Add vertical scroll if content exceeds the max height */
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

thead {
  position: sticky;
  top: 0;
  background-color: #f1f1f1;
  z-index: 10;
}

/* Loading Spinner */
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

/* Maintain container alignment */
.App > div:first-child {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Scrollbar Styling (Optional) */
.sidebar::-webkit-scrollbar,
.content::-webkit-scrollbar {
  width: 8px;
}

.sidebar::-webkit-scrollbar-track,
.content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.sidebar::-webkit-scrollbar-thumb,
.content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.sidebar::-webkit-scrollbar-thumb:hover,
.content::-webkit-scrollbar-thumb:hover {
  background: #555;
}
