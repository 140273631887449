/* src/LogTimeCalculation.css */

/* General Container Styles */
.log-time-calculation-container {
  font-family: "Arial", sans-serif;
  color: #333;
  width: 90%;
  max-width: 1400px; /* Increased max-width for better table display */
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
}

.log-time-title {
  text-align: center;
  font-weight: bold;
  font-size: 2em;
  margin-bottom: 20px;
}

/* KPI Cards Styles */
.kpi-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
}

.kpi-card {
  background-color: #e8f5e9;
  padding: 20px 30px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.kpi-card h4 {
  margin: 0;
  font-size: 1.2em;
  color: #2e7d32;
}

.kpi-card p {
  margin: 10px 0 0;
  font-size: 2em;
  font-weight: bold;
  color: #388e3c;
}

/* Filters Section Styles */
.filters-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
}

.log-time-dropdown-group {
  min-width: 200px;
}

.log-time-form-control {
  width: 100%;
}

.clear-filters-group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.clear-filters-button {
  padding: 10px 20px;
  background-color: #ffebee;
  color: #c62828;
  border: 1px solid #c62828;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.clear-filters-button:hover {
  background-color: #ffcdd2;
}

/* Section Divider */
.section-divider {
  border-top: 1px solid #ddd;
  margin: 30px 0;
}

/* Chart Container Styles */
.log-time-chart-container {
  width: 95%;
  height: 500px;
  margin: 0 auto;
  padding: 25px;
  background-color: #f9f9f9;
  border-radius: 8px;
  text-align: center;
}

.log-time-chart-container h3 {
  margin-bottom: 20px;
  font-size: 1.5em;
}

/* Line Chart Controls Styles */
.line-chart-controls {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.line-chart-form-control {
  min-width: 200px;
}

/* Line Chart Container Styles */
.log-time-line-chart-container {
  width: 95%;
  height: 400px;
  margin: 0 auto;
  padding: 25px;
  background-color: #f9f9f9;
  border-radius: 8px;
  text-align: center;
}

.log-time-line-chart-container h3 {
  margin-bottom: 20px;
  font-size: 1.5em;
}

/* Additional Analysis Styles */
.additional-analysis-container {
  padding: 20px;
  background-color: #f2f2f2;
  margin-top: 30px;
  border-radius: 8px;
  text-align: left;
}

.additional-analysis-container h3 {
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  font-size: 1.5em;
}

.analysis-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.analysis-section p {
  margin: 0;
  font-size: 1em;
}

.analysis-section strong {
  font-weight: bold;
}

/* Detailed Data Table Styles */
.detailed-data-table {
  overflow-x: auto;
  margin-top: 20px;
}

.detailed-data-table table {
  width: 100%;
  border-collapse: collapse;
}

.detailed-data-table th,
.detailed-data-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  font-size: 0.9em;
}

.detailed-data-table th {
  background-color: #f2f2f2;
  color: #333;
}

/* Shift-Based Row Coloring */
.day-shift {
  background-color: #fff9c4; /* Light Yellow */
}

.night-shift {
  background-color: #bbdefb; /* Light Blue */
}

/* UID Text Styling */
.uid-text {
  font-family: "Courier New", monospace;
  color: #444;
}

/* Responsive Design */
@media (max-width: 768px) {
  .filters-section {
    flex-direction: column;
    align-items: center;
  }

  .log-time-dropdown-group {
    width: 100%;
    max-width: 300px;
  }

  .log-time-chart-container,
  .log-time-line-chart-container {
    width: 100%;
    padding: 15px;
  }

  .additional-analysis-container {
    padding: 15px;
  }

  .detailed-data-table table {
    font-size: 0.8em;
  }

  .kpi-container {
    flex-direction: column;
    align-items: center;
  }

  .kpi-card {
    width: 80%;
  }

  .line-chart-controls {
    flex-direction: column;
    align-items: center;
  }

  .line-chart-form-control {
    width: 100%;
    max-width: 300px;
  }
}

/* Shadows for Depth */
.log-time-chart-container,
.log-time-line-chart-container,
.kpi-card,
.additional-analysis-container {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

/* Data Labels */
.chartjs-render-monitor {
  overflow: visible !important;
}

/* Legend Styles */
.chartjs-render-monitor + .chartjs-size-monitor {
  display: none;
}
